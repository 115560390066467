.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.light {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    box-shadow: 0px 0px 10px red;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

/*
Rendering Table for DNS queries
*/
.table-container {
    margin: 20px;
}

table {
    border-collapse: collapse;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 16px;
}

th,
td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}


body {
    background-color: #283747;
}


th {
    text-align: center;
    font-weight: bold;
}

h3 {
    margin-bottom: 10px;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
